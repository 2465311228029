import Stripe from "stripe";
import { __DEV__ } from "@/constants";
import { Logger, PurchasePlan } from "@/api-lib";

export type StripeInvoice = Stripe.Invoice;

export class StripeClient {
  private readonly client: Stripe;
  private readonly webhookSecret: string;
  private logger: Logger;

  constructor(params: {
    stripeKey: string;
    webhookSecret?: string;
    logger: Logger;
  }) {
    this.logger = params.logger;
    this.client = new Stripe(params.stripeKey);
    this.webhookSecret = params.webhookSecret;
  }

  private getPriceKey(purchasePlan: PurchasePlan) {
    if (__DEV__) {
      switch (purchasePlan) {
        case "5_HOURS":
          return "price_1QG25eG2t1iGYIpcSkTIkXPL";
        case "50_HOURS":
          return "price_1QGrOYG2t1iGYIpcY8JgG1xD";
        case "100_HOURS":
          return "price_1QG28FG2t1iGYIpcoUvJjNNY";
      }
    }

    switch (purchasePlan) {
      case "5_HOURS":
        return "price_1QG6VBG2t1iGYIpco0xP7WMS";
      case "50_HOURS":
        return "price_1QGrPeG2t1iGYIpcxEqUiNUa";
      case "100_HOURS":
        return "price_1QG2DSG2t1iGYIpcKFUWB8ao";
    }
  }

  async getPaymentIntent(params: {
    paymentIntentId: string;
  }): Promise<Stripe.PaymentIntent> {
    return await this.client.paymentIntents.retrieve(params.paymentIntentId);
  }

  async updateSubscription(params: {
    subscriptionId: string;
    paymentMethod: string;
  }) {
    try {
      const subscription = await this.client.subscriptions.update(
        params.subscriptionId,
        {
          default_payment_method: params.paymentMethod,
        }
      );

      this.logger.info(
        "StripeClient: Default payment method set for subscription:" +
          subscription.id,
        {}
      );
    } catch (err) {
      this.logger.error(
        `StripeClient: Failed to update the default payment method for subscription: ${params.subscriptionId}`,
        { err }
      );
    }
  }

  async getPaymentLink(params: {
    deviceId: string;
    baseAppUrl: string;
    purchasePlan: PurchasePlan;
    email: string;
    transcriptionId?: string;
  }): Promise<string> {
    try {
      const successUrl = new URL(params.baseAppUrl);
      successUrl.searchParams.set("checkout_success", "true");
      successUrl.searchParams.set("plan", params.purchasePlan);

      const cancelUrl = new URL(params.baseAppUrl);
      cancelUrl.searchParams.set("checkout_success", "false");

      const session = await this.client.checkout.sessions.create({
        success_url: successUrl.toString(),
        cancel_url: cancelUrl.toString(),
        customer_email: params.email,
        metadata: {
          deviceId: params.deviceId,
          purchasePlan: params.purchasePlan,
          transcriptionId: params.transcriptionId,
        },
        line_items: [
          { price: this.getPriceKey(params.purchasePlan), quantity: 1 },
        ],
        mode: "payment",
      });

      return session.url;
    } catch (e) {
      this.logger.error("StripeClient: getStripeCheckoutLink failed", { e });

      return "";
    }
  }

  async getWebhookEvent(params: { body: string; signature: string }) {
    return await this.client.webhooks.constructEventAsync(
      params.body,
      params.signature,
      this.webhookSecret
    );
  }

  async getBillingPortalUrl(params: {
    customerId: string;
    baseAppUrl: string;
  }): Promise<string> {
    const session = await this.client.billingPortal.sessions.create({
      customer: params.customerId,
      return_url: params.baseAppUrl,
    });

    return session.url;
  }

  async getCustomer(customerId: string): Promise<Stripe.Customer> {
    return (await this.client.customers.retrieve(
      customerId
    )) as Stripe.Customer;
  }

  async getInvoice(invoiceId: string): Promise<Stripe.Invoice> {
    return (await this.client.invoices.retrieve(invoiceId)) as Stripe.Invoice;
  }
}
