export * from "./Colors";
export * from "../../mobile/_shared/transcriptionOptions";

export const PRICE_MONTHLY = 19.99;
export const MAX_TRANSCRIPTION_DURATION_IN_SECONDS = 3600 * 10; // 10 hours
export const PRICE_YEARLY = 199;

export const PRIVACY_POLICY =
  "https://docs.google.com/document/d/e/2PACX-1vQdFYIrFpqoz-1Jhd7UFkucjrA1eLiu72Cd6Zep7qX6DvHsfAD__BpEi7qlYs4HCMvrti3z02JxOhrF/pub";
export const TERMS_OF_USE =
  "https://docs.google.com/document/d/e/2PACX-1vQdFYIrFpqoz-1Jhd7UFkucjrA1eLiu72Cd6Zep7qX6DvHsfAD__BpEi7qlYs4HCMvrti3z02JxOhrF/pub";

export const WEB_GUEST_TRIM_TIME_IN_SECONDS = 5 * 60; // 5 minutes

export const WEB_COUNTRIES_WITHOUT_GOOGLE_SIGN_IN = ["CO", "PE"];

export const WEB_GUEST_USAGE_IN_SECONDS_PER_DAY = 15 * 60; // 15 minutes

export const AVAILABLE_LOCALES = [
  "ar",
  "en",
  "es",
  "it",
  "pt",
  "fr",
  "nl",
  "de",
];

export const DESKTOP_WIDTH = 1079;

export const FREE_MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024; // 1GB

export const SUPPORTED_FILE_EXTENSIONS = [
  ".3ga",
  ".webm",
  ".8svx",
  ".mts",
  ".m2ts",
  ".ts",
  ".aac",
  ".mov",
  ".ac3",
  ".mp2",
  ".aif",
  ".mp4",
  ".m4v",
  ".aiff",
  ".mxf",
  ".alac",
  ".amr",
  ".ape",
  ".au",
  ".dss",
  ".flac",
  ".flv",
  ".m4a",
  ".m4b",
  ".m4p",
  ".m4r",
  ".mp3",
  ".mpga",
  ".ogg",
  ".oga",
  ".mogg",
  ".opus",
  ".qcp",
  ".tta",
  ".voc",
  ".wav",
  ".wma",
  ".wv",
];

export const __DEV__ =
  Boolean(process) && process.env.NODE_ENV === "development";
