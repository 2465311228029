import React from "react";
import { Pressable, Text, TextStyle } from "@/ui";
import Link from "next/link";
import { Colors } from "@/constants";

const TextLink = ({
  text,
  path,
  fontSize = 16,
  style = {},
  onPress,
}: {
  text: string;
  path?: string;
  onPress?: () => void;
  fontSize?: number;
  style?: TextStyle;
}) => {
  if (onPress) {
    return (
      <Pressable onPress={onPress}>
        <Text style={style}>{text}</Text>
      </Pressable>
    );
  }

  return (
    <Link href={path} style={{ textDecoration: "none" }}>
      <Text
        accessibilityRole="header"
        aria-level={3}
        style={{
          fontSize: fontSize,
          color: Colors.link,
          textDecorationLine: "underline",
          textDecorationStyle: "dotted",
          textDecorationColor: Colors.accent,
          ...style,
        }}
      >
        {text}
      </Text>
    </Link>
  );
};

export default TextLink;
