import { View, Text, StyleSheet } from "@/ui/index";
import { Colors, PRIVACY_POLICY, TERMS_OF_USE } from "@/constants";
import { useTranslation } from "react-i18next";
import TextLink from "./TextLink";

const LandingFooter = () => {
  const { t } = useTranslation();

  const _contactUs = (type: "footer_section" | "need_more_section") => {
    const subject = {
      footer_section: "I have a question about 1Transcribe web app",
      need_more_section: "I have custom transcription demands",
    };

    _openLink(
      `mailto:hello@1transcribe.com?subject=${encodeURI(subject[type])}`
    );
  };

  const _openLink = (link: string) => {
    if (typeof window !== "object") return;
    window.open(link, "_blank");
  };

  return (
    <View style={s.footer}>
      <Text style={s.copyright}>{t("landingPage.copyright")}</Text>
      <View style={s.footerLinks}>
        <TextLink
          onPress={() => window.open("/transcribe-audio-to-text", "_self")}
          style={s.footerLink}
          text="Transcribe audio to text"
        />
        <TextLink
          onPress={() => window.open("/transcrever-audio-em-texto", "_self")}
          style={s.footerLink}
          text="Transcrever audio em texto"
        />
        <TextLink
          onPress={() => window.open("/transcribir-audio-a-texto", "_self")}
          style={s.footerLink}
          text="Transcribir audio a texto"
        />
        <TextLink
          onPress={() => window.open("/transcrire-audio-en-texte", "_self")}
          style={s.footerLink}
          text="Transcrire audio en texte"
        />

        <TextLink
          style={s.footerLink}
          text={t("landingPage.m4aToMp3")}
          path="/convert-m4a-to-mp3"
        />

        <TextLink
          style={s.footerLink}
          text={t("landingPage.termsOfUse")}
          path={TERMS_OF_USE}
        />

        <TextLink
          style={s.footerLink}
          text={t("landingPage.privacyPolicy")}
          path={PRIVACY_POLICY}
        />

        <TextLink
          style={s.footerLink}
          text={t("landingPage.contactUs")}
          onPress={() => _contactUs("footer_section")}
        />
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  footer: {
    marginTop: 50,
    padding: 20,
    borderTopWidth: 1,
    borderTopColor: Colors.borderColor,
  },
  copyright: {
    textAlign: "center",
    color: Colors.subtitle,
    marginBottom: 10,
  },
  footerLinks: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  footerLink: {
    color: Colors.text,
    fontSize: 14,
    lineHeight: 24,
    textDecorationLine: "underline",
    textDecorationStyle: "dotted",
    textDecorationColor: Colors.accent,
    marginHorizontal: 10,
  },
});

export default LandingFooter;
