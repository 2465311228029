import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import { DbClient, Logger, ColdEmailClient } from "@/api-lib";

interface Env {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
  ZERO_BOUNCE_SECRET_KEY: string;
}

type SubjectId =
  | "shareFeedback"
  | "sendTranscriptionCompletedEmail"
  | "sendOneTimeCode";

export class SesClient {
  private client: SESClient;
  private region = "eu-west-1";
  private fromAddress = `"1Transcribe" <support@1transcribe.com>`;
  private zeroBounceBaseUrl: string;
  private coldEmailClient: ColdEmailClient;
  private logger: Logger;
  private dbClient: DbClient;

  constructor(env: Env, logger: Logger, dbClient: DbClient) {
    this.logger = logger;
    this.client = new SESClient({
      region: this.region,
      credentials: {
        accessKeyId: env.AWS_ACCESS_KEY_ID,
        secretAccessKey: env.AWS_SECRET_ACCESS_KEY,
      },
    });
    this.zeroBounceBaseUrl = `https://api.zerobounce.net/v2/validate?api_key=${env.ZERO_BOUNCE_SECRET_KEY}`;
    this.dbClient = dbClient;
    this.coldEmailClient = new ColdEmailClient({ env, logger });
  }

  private async sendEmail(params: {
    toAddress: string;
    subject: string;
    body: string;
    subjectId: SubjectId;
  }): Promise<void> {
    if (!(await this.isValidEmail(params.toAddress))) {
      return this.logger.warn(
        `sesClient: ${params.toAddress} is not valid, skipping email`,
        { toAddress: params.toAddress }
      );
    }

    try {
      const response = await this.coldEmailClient.sendEmail({
        isWarmUp: true,
        recipient: params.toAddress,
        subject: params.subject,
        emailBody: params.body,
      });

      if (response.success) {
        return await this.logger.info(
          `sesClient: Email sent to ${params.toAddress} | subjectId: ${params.subjectId}`,
          {
            fromColdEmailClient: true,
            subjectId: params.subjectId,
            fromEmail: response.fromEmail,
          }
        );
      }

      await this.client.send(
        new SendEmailCommand({
          Destination: { ToAddresses: [params.toAddress] },
          Message: {
            Body: { Html: { Charset: "UTF-8", Data: params.body } },
            Subject: { Charset: "UTF-8", Data: params.subject },
          },
          Source: this.fromAddress,
        })
      );

      await this.logger.info(
        `sesClient: Email sent to ${params.toAddress} | subjectId: ${params.subjectId}`,
        {
          fromColdEmailClient: false,
          subjectId: params.subjectId,
          fromEmail: this.fromAddress,
        }
      );
    } catch (e) {
      this.logger.error(
        `sesClient: Failed to send email: ${params.subjectId} | email: ${params.toAddress}`,
        { error: e }
      );
      return e;
    }
  }

  async isValidEmail(email: string): Promise<boolean> {
    try {
      if (email.endsWith("@1transcribe.com")) return false;

      const verificationResult = await this.dbClient.getEmailValidation(email);

      if (verificationResult) {
        await this.logger.warn(
          `sesClient: ${email} is ${verificationResult.isValid ? "valid" : "invalid"}`,
          { verificationResult }
        );

        return verificationResult.isValid;
      }

      let zeroBounceResult = { status: "valid" };

      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        try {
          zeroBounceResult = await fetch(
            `${this.zeroBounceBaseUrl}&email=${email}`,
            { signal: controller.signal }
          ).then((res) => res.json());
        } finally {
          clearTimeout(timeoutId);
        }
      } catch (e) {
        this.logger.warn(`sesClient: ZeroBounceError: ${e}`, { e });
      }

      this.logger.info("sesClient: ZeroBounceResult", { zeroBounceResult });

      const isValid = zeroBounceResult.status === "valid";

      await this.dbClient.createEmailValidation({
        email,
        isValid,
        status: zeroBounceResult.status,
        apiResponseJson: JSON.stringify(zeroBounceResult),
      });

      return isValid;
    } catch (e) {
      this.logger.error(`sesClient: ValidateEmailError: ${e}`, { e });

      return true;
    }
  }

  async shareFeedback(params: {
    feedback: string;
    userId: string;
    email: string;
  }) {
    const feedbackText = `
  <html>
  <body>
    <br>${params.feedback} <br><br>
    <br>email: ${params.email} <br>
    <br>userId: ${params.userId} <br>
  </body>
</html>
  `.trim();

    await this.sendEmail({
      toAddress: "sadeck@1transcribe.com",
      subject: `1Transcribe - Feedback from ${params.email}`,
      body: feedbackText,
      subjectId: "shareFeedback",
    });
  }

  getOneTimeCodeEmail(params: { email: string; oneTimeCode: number }) {
    return {
      subject: "Your one-time code",
      body: `
      <html>
        <body>
          <p>Hey there!</p>
          <p>Your one-time code is <b>${params.oneTimeCode}</b></p>
          <p>Thanks for using 1Transcribe!</p>
        </body>
      </html>
      `.trim(),
    };
  }

  async sendOneTimeCode(params: { email: string; oneTimeCode: number }) {
    const { subject, body } = this.getOneTimeCodeEmail(params);

    await this.sendEmail({
      toAddress: params.email,
      subject,
      body,
      subjectId: "sendOneTimeCode",
    });
  }
}
