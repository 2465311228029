import { useGoogleLogin } from "@react-oauth/google";
import * as serverClient from "@/models/serverClient";
import { useUserSlice } from "./useUserSlice";
import { datadogRum } from "@datadog/browser-rum";
import { useAuth } from "./auth";
import { useUISlice } from "./useUISlice";
import { datadogLogs } from "@datadog/browser-logs";

let _callback: ((accessToken: string) => void) | undefined = undefined;

export const useGoogleAuth = () => {
  const { currentAuthUser } = useAuth();

  const googleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const authenticateResponse = await serverClient.authenticateAsync({
        type: "OnGoogleSignIn",
        payload: {
          googleAccessToken: credentialResponse.access_token,
          deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          deviceLanguage: navigator.language.toLowerCase(),
        },
      });

      if (
        authenticateResponse &&
        authenticateResponse.data &&
        authenticateResponse.data.accessToken
      ) {
        const { accessToken } = authenticateResponse.data;

        const user = currentAuthUser(accessToken);

        datadogRum.setUser({ id: user.deviceId, email: user.email });

        datadogLogs.logger.info("Funnel 0: authenticated with Google", {
          user,
        });

        useUserSlice.getState().setUserToken(accessToken);
        useUISlice.getState().setAlertPromptSignIn(undefined);

        if (typeof _callback === "function") {
          _callback(accessToken);
          _callback = undefined;
        }
      }
    },
    onError: () => {},
  });

  const signInWithGoogle = (callback?: (accessToken: string) => void) => {
    _callback = callback;
    googleLogin();
  };

  return { signInWithGoogle };
};
